if (Pace) {
  Pace.on("done", initSwiper);
} else {
  $(window).load(initSwiper);
}

$(document).ready(function () {
  var timeInterval = setInterval(function () {
    var date = moment().format("DD / MM / YYYY HH:mm:ss");
    $(".date").text(date);
  }, 1000);

  $(".js-tilt").tilt({
    scale: 1.2,
  });

  randomizeGlare();
  getCurrentGame();

  var scene = document.getElementById("scene");
  if (scene) {
    var parallaxInstance = new Parallax(scene, {
      relativeInput: true,
    });
  }

  // Scroll reveal
  window.sr = ScrollReveal();

  //Index
  // selector, duration, delay, distance, origin
  scrollReveal(".line.left", 1000, 0, 100, "right");
  scrollReveal(".line.right", 1000, 0, 100, "left");
  scrollReveal(".service-footer", 600, 0, 345, "bottom");
  scrollReveal(".game-title .game-start", 600, 300, 345, "right");
  scrollReveal(".game-title .game-end", 600, 600, 345, "right");
  scrollReveal(".game-bg", 600, 800, 345, "right");
  scrollReveal(".current-game", 600, 900, 345, "right");
  scrollReveal(".games-container .button.go", 600, 1000, 345, "bottom");
  scrollReveal(".games-container .more-games", 600, 1000, 345, "right");
  scrollReveal(".games-container .wrapper .right", 600, 100, 345, "right");

  //Mobile
  scrollReveal(".phone-wrapper .mobile1", 600, 0, 345, "bottom");
  scrollReveal(".phone-wrapper .mobile2", 600, 400, 100, "right");
  scrollReveal(".phone-wrapper .mobile3", 600, 400, 100, "left");
  scrollReveal(".phone-wrapper .mobile4", 600, 800, 200, "right");
  scrollReveal(".phone-wrapper .mobile5", 600, 800, 200, "left");
  scrollReveal(".phone-wrapper .mickey", 600, 800, 200, "left");

  stragerScrollReveal(".service-wrapper > div", 600, 200, 300, "bottom");
  stragerScrollReveal(".service .service-item", 600, 100, 300, "bottom");
  stragerScrollReveal(".service.mobile .service-item", 600, 100, 300, "right");
  stragerScrollReveal(".partner li", 600, 100, 200, "bottom");

  // Detect visible
  var increaseServiceAmountExecuted = false;
  $(window).scroll(function () {
    if ($(".service-item").visible() && !increaseServiceAmountExecuted) {
      increaseServiceAmountExecuted = true;
      increaseServiceAmount("span.deposit-value", 25, 100, "");
      increaseServiceAmount("span.withdraw-value", 120, 30, "time");
      increaseServiceAmount("span.bank-value", 34, 100, "");
    }
    // if(increaseServiceAmountExecuted){
    // $(window).unbind('scroll');
    // }
    if ($(window).scrollTop() > 100) {
      $(".sticky-nav-list .back-to-top").css("opacity", "1");
    } else {
      $(".sticky-nav-list .back-to-top").css("opacity", "0");
    }
  });

  $(".sticky-nav-list .back-to-top a").click(function (e) {
    e.preventDefault();
    $("html, body").animate({ scrollTop: 0 });
  });

  tabContentItemToggle(".sub-content-item-header", ".sub-content-item-content");
  tabSwitchContentToggle(
    ".tab-container.left-panel .tab-switch ul li a",
    ".tab-container.left-panel .tab-content .sub-content"
  );
});

function initSwiper() {
  const bannerList = window.banner;
  const indexContainer = $("#index-banner .swiper-wrapper");
  const isLoggedIn = $(".before-login.hide").length !== 0;

  bannerList &&
    bannerList !== 0 &&
    bannerList.forEach(function (item) {
      const { imageUrl, redirectUrl, redirectGroup, redirectPage, bannerType } = item;

      if (imageUrl) {
        /**
         * Redirect Group Option
         * 0 - Promo
         * 1 - Join Us
         * 2 - Lottery
         * 3 - Product
         * 4 - Others inner page (Loyalty, Customer Service)
         * 5 - 自定义
         */

        const banner = $(`
            <div class="swiper-slide" data-swiper-autoplay="5000">
              <div class="bg" style="background-image: url(${imageUrl})"></div>
            </div>
          `);

        banner.click(() =>
          window.initBannerCarouselRedirection({
            redirectUrl,
            redirectGroup,
            redirectPage,
            isLoggedIn,
          })
        );

        /**
         * index append 首页type banner, xycly promo.html no banner
         * 轮播图类型 bannerType
         * 0 - 首页
         * 1 - 优惠活动
         * 2 - 其他
         */
        if (bannerType === 0) {
          indexContainer.append(banner);
        }
      }
    });

  if (bannerList && bannerList.length) {
    var mySwiper = new Swiper(".swiper-container", {
      speed: 400,
      autoplay: {
        delay: 5000,
      },
      loop: true,
      // If we need pagination
      pagination: {
        el: ".swiper-pagination",
      },
    });
  }
}

function randomizeGlare() {
  $(".game-list .game-item").each(function (k, i) {
    var random = Math.floor(Math.random() * 10) + 1;
    var randomTime = Math.floor(Math.random() * 5) + 1;
    var count = k + 1;
    $(".game-item:nth-child(" + count + ") .glare").css("top", random + "px");
    $(".game-item:nth-child(" + count + ") .glare").css("left", random + "px");
    $(".game-item:nth-child(" + count + ") .glare").css("animation-duration", random + "s");
  });
}

function getCurrentGame() {
  $(".game-item a").click(function (e) {
    e.preventDefault();
    var currentGame = $(this).attr("class");
    var currentGameName = $(this).text();
    $(".current-game div").attr("class", "");
    $(".current-game div").addClass(currentGame);
    $(".current-game div").addClass("blur");
    setTimeout(function () {
      $(".current-game div").removeClass("blur");
    }, 600);

    $("#in-game").attr("href", "/player/lottery/" + currentGameName);
  });
}

function scrollReveal(selector, duration, delay, distance, origin) {
  sr.reveal(selector, {
    duration: duration,
    delay: delay,
    distance: distance + "px",
    origin: origin,
  });
}

function stragerScrollReveal(selector, duration, delay, distance, origin) {
  for (var i = 1; i <= $(selector).length; i++) {
    sr.reveal(selector + ":nth-child(" + i + ")", {
      duration: duration,
      delay: delay * i,
      distance: distance + "px",
      origin: origin,
    });
  }
}

function increaseServiceAmount(selector, max, speed, type) {
  var count = 0;
  var increaseServiceAmountInterval = setInterval(function () {
    count += 1;
    if (type === "time") {
      var formatted = moment.utc(count * 1000).format("mm:ss");
      $(selector).text(formatted);
    } else {
      $(selector).text(count);
    }
    if (count === max) {
      clearInterval(increaseServiceAmountInterval);
    }
  }, speed);
}

function tabContentItemToggle(selector, siblings) {
  $(selector).click(function (e) {
    e.preventDefault();
    $(this).siblings(siblings).slideToggle();
  });
}

function tabSwitchContentToggle(selector, target) {
  $(selector).click(function (e) {
    e.preventDefault();
    $(this).parent("li").addClass("active");
    $(selector).not($(this)).parent("li").removeClass("active");
    var index = $(this).parent("li").index() + 1;

    $(target + ":nth-child(" + index + ")").addClass("active");
    $(target + ":not(:nth-child(" + index + "))").removeClass("active");
  });
}

//Load trans
var transactionsContainer = $("#trans_list > div > div");
transactionsContainer.children().remove();
if (!!transactionsContainer.length) {
  api.getTrans(function (response) {
    response.data.result.forEach(function (info, i) {
      // console.log(info);
      transactionsContainer.append(
        $(
          `
            <div class="info${i == 0 ? " active" : ""}" style="display: inline-block; min-width: 100%">
              <a href="#!"> 恭喜会员 ${info.username} 提款 ${-info.amount} 元成功，请及时查看银行卡账目。 </a>
            </div>
          `
        )
      );
    });
  });
}

// promo
function registerPromo(promoId) {
  api.registerPromo(promoId);
}

function renderPromo(promoData) {
  if (Array.isArray(promoData)) {
    var promoWrapper = $(`<div class="tab-content"></div>`);
    promoData.forEach(function (value, index) {
      var promoItem = $(`<div class="sub-content active" data-type="${value.type}"></div>`);
      var promoId = value.promoId;
      var requestToJoin = value.requestToJoin;
      var firstDepositPromoJoinMsg = value.firstDepositPromoJoinMsg;

      promoItem.append(
        $(`
            <div class="sub-content-item">
              <div class="sub-content-item-header">
                ${value.imagePath ? `<img src="${value.imagePath}" alt="banner" alt="${value.title}" />` : ``}
              </div>
              <div class="sub-content-item-content">
                <h3>${value.title}</h3>
                ${value.content}
                ${
                  requestToJoin
                    ? `
                             <div
                               class="item-footer"
                               style="display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; height: 50px;font-size: 14px"
                             >
                               ${
                                 firstDepositPromoJoinMsg
                                   ? `
                                             <div class="item-footer--info" style="color: #555;">
                                               ${firstDepositPromoJoinMsg}
                                             </div>
                                           `
                                   : `
                                             <button
                                               class="item-footer--register-promo"
                                               data-id="${promoId}"
                                               style="background-color:#6d0db1;cursor:pointer;color:#fff;outline: 0;border:0;height:35px; width:120px; border-radius:6px; font-size:16px;"
                                             >
                                               申请参与
                                             </button>
                                           `
                               }
                             </div>
                           `
                    : ``
                }
              </div>
            </div>
          `)
      );

      promoWrapper.append(promoItem);
    });

    $(".promo .tab-content-wrapper").html(promoWrapper);
    initPromoControl();
  }

  $(document).on("click", ".item-footer--register-promo", function (e) {
    e.preventDefault();
    var promoId = $(this).data("id");
    registerPromo(promoId);
  });

  $(document).on("click", ".sub-content-item-header", function () {
    var content = $(this).siblings(".sub-content-item-content");

    if (content.hasClass("show")) {
      content.removeClass("show");
      content.slideUp();
    } else {
      content.addClass("show");
      content.slideDown();
    }
  });
}

function initPromoControl() {
  // $(document).on("click", ".tab-switch [data-type]", function(event) {
  //   const target = $(event.target)
  //   const type = target.attr("data-type");
  //   target.parent().siblings().removeClass('active')
  //   target.parent().addClass('active')
  //   if (type != -1) {
  //     $(".sub-content").removeClass("active");
  //     $(`.sub-content[data-type="${type}"]`).addClass("active");
  //   } else {
  //     $(".sub-content").addClass("active");
  //   }
  // });
}

if (window.promo) {
  renderPromo(window.promo);
} else {
  Object.defineProperty(window, "promo", {
    set: function (data) {
      renderPromo(data);
    },
  });
}

// hash detection
$(function () {
  function detectHash() {
    var currentHash = location.hash.slice(1);

    $(".tab-item[data-hash='" + currentHash + "']")
      .closest("li")
      .addClass("active")
      .siblings()
      .removeClass("active");

    $(".faq .sub-content[data-hash='" + currentHash + "']")
      .addClass("active")
      .siblings()
      .removeClass("active");
  }

  detectHash();

  $(".tab-item[data-hash]").click(function (event) {
    event.preventDefault();
    location.hash = $(this).attr("data-hash");
    var index = $(this).closest("li").index();

    $(this).closest("li").addClass("active").siblings().removeClass("active");

    $(".faq .sub-content").eq(index).addClass("active").siblings().removeClass("active");
  });

  $('.head .nav a[href*="html#"]').click(function (e) {
    setTimeout(detectHash, 10);
    $(window).scrollTop(0);
  });

  $('.foot p a[href*="html#"]').click(function (e) {
    setTimeout(detectHash, 10);
    $(window).scrollTop(0);
  });

  $(".first-footer a").click(function (e) {
    setTimeout(detectHash, 10);
    $(window).scrollTop(0);
  });
});

$(".qrclose").click(function () {
  $(".qrcode").fadeOut(500);
});

function renderKefuQrCode() {
  var currTarget = $("#mobile-qr canvas")[0];
  var url = location.href + "kefu.html";
  const options = {
    margin: 1,
    width: 150,
    height: 150,
    color: {
      dark: "#000",
    },
  };

  QRCode.toCanvas(currTarget, url, options, function (error) {
    if (error) console.error(error);
  });
}

renderKefuQrCode();

$(".customer-service a").click(function (e) {
  e.preventDefault();

  $(this).siblings(".sticky-hidden.kefu").toggleClass("show");

  $("#kefu-popup").click(function (e) {
    popup("kefu.html");
    $(".sticky-hidden.kefu").removeClass("show");
  });

  function popup(url) {
    var width = 540;
    var height = 600;
    var left = screen.width - 660;
    var top = 0;
    var params = "width=" + width + ", height=" + height;
    params += ", top=" + top + ", left=" + left;
    params += ", directories=no";
    params += ", location=no";
    params += ", menubar=no";
    params += ", resizable=no";
    params += ", scrollbars=no";
    params += ", status=no";
    params += ", toolbar=no";
    var newwin = window.open(url, "customWindow", params);

    setWindowTitle(newwin); // Starts checking

    function setWindowTitle(newwin) {
      if (newwin.document) {
        // If loaded
        newwin.document.title = "";
      } // If not loaded yet
      else {
        setTimeout(setWindowTitle, 10); // Recheck again every 10 ms
      }
    }
    if (window.focus) {
      newwin.focus();
    }
    return false;
  }
});

if ($("body.kefu-popup").length !== 0) {
  $(".close").click(function () {
    window.close();
  });
  if (getMobileOperatingSystem() === "android" || getMobileOperatingSystem() === "ios") {
    $(".footer").hide();
    $(".close").addClass("no-font");
    $(".instruction").fadeIn();
    //Detect standalone webapp in iOS
    if (!("standalone" in window.navigator && !window.navigator.standalone) && getMobileOperatingSystem() === "ios") {
      $(".instruction").addClass("hidden");
    }
    //Detect standalone webapp in Android done in CSS @media rule
  }
  if (isWeixinBrowser() || isQQBrowser()) {
    $(".instruction").fadeOut();
    $("iframe").addClass("blur");
    $(".image-container").fadeIn();
    $(".overlay").fadeIn();
  }
  $(".close-instruction").click(function () {
    $(".instruction").fadeOut();
  });
}

function isWeixinBrowser() {
  return /micromessenger/.test(navigator.userAgent.toLowerCase());
}
function isQQBrowser() {
  return /qbwebviewtype/.test(navigator.userAgent.toLowerCase());
}
function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  if (/android/i.test(userAgent)) {
    return "android";
  }
  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "ios";
  }
  return "unknown";
}
